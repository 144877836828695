@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full;
  }

  html {
    font-feature-settings: 'salt' on;

    @apply antialiased text-stone;
  }

  #__next {
    @apply flex flex-col items-stretch w-full min-h-full;
  }

  @supports (font-variation-settings: normal) {
    html { font-family: 'Poppins', sans-serif; }
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('../public/fonts/poppins-v20-latin-regular.woff2') format('woff2'),
        url('../public/fonts/poppins-v20-latin-regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
        url('../public/fonts/poppins-v20-latin-500.woff2') format('woff2'),
        url('../public/fonts/poppins-v20-latin-500.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
        url('../public/fonts/poppins-v20-latin-600.woff2') format('woff2'),
        url('../public/fonts/poppins-v20-latin-600.woff') format('woff');
  }
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    @apply m-0 appearance-none;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}